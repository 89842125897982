/* src/components/NewsAggregator.module.css */
.container {
    text-align: left;
    min-height: 100vh;
    padding: 20px;
    border-radius: 8px;
  }
  
  .title {
    font-size: 2rem;
    text-align : center;
    color: #87CEEB;
    margin-bottom: 20px;
  }
  
  .error {
    color: red;
    margin-top: 10px;
  }
  
  .newsList {
    list-style-type: none;
    padding: 0;
  }
  
  .newsItem {
    margin-bottom: 15px;
    padding: 10px;
    background-color: white;
    border: 1px solid white;
    border-radius: 4px;
    box-shadow: inset;
  }
  
  .link {
    font-size: 20px;
    color: #6BAED8;
    text-decoration: none;
  }
  
  .link:hover {
    text-decoration: underline;
  }
  
  .source {
    font-size: 14px;
    color: #87CEEB;
  }
  