.ConnectFour {
    text-align: center;
    margin: 0 auto;
  }
  
  .restartButton {
    margin-top: 20px;

    font-family: 'Open Sans', serif;
    padding: 10px;
    font-size: 10px;
    cursor: pointer;
  }
  .restartButton:hover {
    background-color: lightblue;
  }
  
  
  .board {
    margin: 20px auto; /* Add margin to create space between the board and the button */
    
    border-collapse: collapse;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.25);
    background: #87CEEB;
    border-radius: 5px;
    overflow: hidden;
  }
  
  .cell {
    width: 50px;
    height: 50px;
    border: solid 2px white;
    position: relative;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.25);

  }
  
  .piece {
    margin: auto;
    width: 80%;
    height: 80%;
    border-radius: 50%;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    animation: drop 0.4s ease-out forwards;
  }
  
  @keyframes drop {
    0% {
      transform: translate(-50%, -150%);
    }
    100% {
      transform: translate(-50%, -50%);
    }
  }
  
  .player1 {
    background-color: red;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3);
  }
  
  .player2 {
    background-color: blue;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3);
  }
  
  .columnTop {
    border: dashed 1px lightgray;
    cursor: pointer;
    background: transparent;
    transition: background-color 0.3s;
  }
  
  .columnTop:hover {
    background-color: lightblue;
  }
  
  .alert {
    font-size: 16px;
    color: red;
    margin-top: 20px;
    height: 20px; /* Reserve space for the alert text */
  }
  