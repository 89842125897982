.gridContainer {
    display: flex;
    justify-content: center; /* Centers the card horizontally */
    align-items: center; /* Centers the card vertically if the container has height */
    padding: 20px;
    min-height: 50vh; /* Optional: Ensures the container takes full height for vertical centering */
  }

  .cardWrap {
    margin: 10px;
    transform: perspective(500px); /* Reduced perspective for less dramatic 3D effect */
    transform-style: preserve-3d;
    cursor: pointer;
    width: 100%;
    max-width: 400px; 
    height: 400px; 
    box-sizing: border-box;
}
  
  .card {
    position: relative;
    width: 100%;
    height: 100%; /* Make card height match width */
    background-color: #333;
    overflow: hidden;
    border-radius: 10px;
    box-shadow:
      rgba(0, 0, 0, 0.66) 0 30px 60px 0,
      inset white 0 0 0 1px,
      inset rgba(255, 255, 255, 0.5) 0 0 0 6px;
    transition: 1s cubic-bezier(0.445, 0.05, 0.55, 0.95);
  }
  
  .cardBg {
    opacity: 0.8; /* Increase the opacity slightly */
    position: absolute;
    top: 0;
    left: 0;
    width: 100%; /* Increase the size of the image */
    height: 100%;
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    transition:
      1s cubic-bezier(0.445, 0.05, 0.55, 0.95),
      opacity 5s 1s cubic-bezier(0.445, 0.05, 0.55, 0.95);
    pointer-events: none;
}
  
  .cardInfo {
    padding: 20px;
    position: absolute;
    bottom: 0;
    color: black;
    transform: translateY(40%);
    transition: 0.6s 1.6s cubic-bezier(0.215, 0.61, 0.355, 1);
  }
  
  .cardInfo h1 {
    font-family: "Playfair Display";
    font-size: 1.5rem;
    font-weight: 700;
    text-shadow: rgba(0, 0, 0, 0.5) 0 10px 10px;
  }
  
  .cardInfo p {
    opacity: 0;
    text-shadow: rgba(0, 0, 0, 1) 0 2px 3px;
    transition: opacity 0.6s 1.6s cubic-bezier(0.215, 0.61, 0.355, 1);
  }
  